import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

interface FormProps<TFormData> {
  children: (props: FormChildProps<TFormData>) => void;
  onValidate: (formData: TFormData) => boolean;
  onSubmitAsync?: (formData: TFormData) => Promise<any>;

  id?: string;
  className: string;
}

interface FormChildProps<TFormData> {
  formData: TFormData;
  formLoading: boolean;
  formError: string | null;
  formSubmitted: boolean;

  setFieldValue: (fieldName: string, value?: string) => void;
}

const Form = <TFormData extends Record<string, any>>({ children, onValidate, onSubmitAsync, ...props }: FormProps<TFormData>) => {
  const location = useLocation();

  const [formData, setFormData] = useState<TFormData>({} as TFormData);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [formError, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    // Parse query string to get initial form state
    if (location.search) {
      const initState = qs.parse(location.search, { ignoreQueryPrefix: true });

      setFormData(initState as TFormData);
    }
  }, []);

  const setFieldValue = (fieldName: string, value?: string) => {
    setError(null);

    // Update the field value in state
    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (onValidate && !onValidate(formData)) {
      setError('All fields are required.');
      return;
    }

    try {
      // Display loading indicator
      setFormLoading(true);

      // Handle async submission operation
      if (onSubmitAsync) {
        await onSubmitAsync(formData);
      }

      // Reset form state
      setFormData({} as TFormData);
      setFormSubmitted(true);
    }
    catch {
      // Display errors on failure
      setError('Failed to submit form. Please try again.');
    }
    finally {
      // End loading indicator
      setFormLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      {...props}>
      <>
        {children({
          formData,
          formLoading,
          formError,
          formSubmitted,
          setFieldValue
        })}
      </>
    </form>
  );
};

export default Form;