import { useEffect } from 'react';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';

/**
 * ScrollReset
 *
 * @description Component to reset scroll to the top
 */
const ScrollReset: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (null);
};

export default withRouter(ScrollReset);