import React from 'react';

import { Align, Span } from 'types/enums';
import { NavigationMenu } from 'components/layout';

import styles from './Header.module.scss';

/**
 * IHeader
 *
 * @description Properties for Header component
 * @property {Align | undefined} alignment Layout alignment
 * @property {Span | undefined}  span      Span
 */
interface IHeader {
  alignment?: Align;
  span?: Span;
}

const Header: React.FC<IHeader> = ({ children, alignment = Align.Left, span = Span.Full }) => {
  let rootClasses = '';
  let contClasses = styles.content;

  switch (alignment) {
    case Align.Center:
      rootClasses += ' center';
      break;
    case Align.Right:
      rootClasses += ' right';
      break;
  }

  switch (span) {
    case Span.Full:
      contClasses += ' fullSection';
      break;
    case Span.Split:
      contClasses += ' splitSection';
      break;
  }

  return (
    <>
      <NavigationMenu />
      <section className={`${styles.headerSection} ${rootClasses.trim()}`}>
        <div className={contClasses.trim()}>
          {children}
        </div>
      </section>
    </>
  );
};

export default Header;