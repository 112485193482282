import React from 'react';

import { IconBlock } from 'components';

import './FeatureList.scss';

/**
 * IFeatureItem
 *
 * @description Single feature item properties
 */
interface IFeatureItem {
  title: string | React.ReactNode;
  subTitle?: string;
  tag?: string;
  alt: string;
  img: string;
  content: string;
}

/**
 * IFeatureList
 *
 * @description Component props for feature list
 */
interface IFeatureList {
  items: Array<IFeatureItem>;
  small?: boolean;
}

/**
 * FeatureList
 *
 * @description List of featured content
 */
const FeatureList: React.FC<IFeatureList> = ({ items, small }) => (
  <ul className={`feature-list ${small ? 'feature-list--small' : ''}`}>
    {
      items.map(item => (
        <li key={item.alt.replaceAll(' ', '')}>
          <IconBlock
            title={item.title}
            subTitle={item.subTitle}
            tag={item.tag}
            alt={item.alt}
            img={item.img}
          >
            <p>{item.content}</p>
          </IconBlock>
        </li>
      ))
    }
  </ul>
);

export default FeatureList;