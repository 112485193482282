import React from 'react';

import styles from './IconBlock.module.scss';

/**
 * IconBlockProps
 *
 * @description Component props for IconBlock
 */
interface IIconBlock {
  title: string | React.ReactNode;
  subTitle?: string;
  img: string;
  alt: string;
  tag?: string;
}

/**
 * IconBlock
 *
 * @description Icon block for rendering a feature or price
 */
const IconBlock: React.FC<IIconBlock> = ({ children, title, alt, subTitle, tag, img }) => {
  return (
    <div className={styles.iconBlock}>
      <img
        src={img}
        alt={alt}
      />
      <div className={styles.iconTitleContainer}>
        <h3>{title}</h3>
        {
          tag &&
            <span className={styles.tag}>{tag}</span>
        }
      </div>
      {
        subTitle &&
          <h4>{subTitle}</h4>
      }
      {children}
    </div>
  );
};

export default IconBlock;