import React from 'react';

import { Align } from 'types/enums';
import { ContactForm } from 'components';
import {
  Header,
  Footer
} from 'components/layout';

const Contact: React.FC = () => {
  return (
    <>
      <Header alignment={Align.Center}>
        <h1>Get in touch with us and <strong>let us know</strong> what you think</h1>

        <ContactForm />
      </Header>
      <Footer />
    </>
  );
};

export default Contact;