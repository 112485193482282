import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import { FeatureList } from 'components/common';
import {
  Footer,
  FeatureSection,
  SplitSection,
  NavigationMenu
} from 'components/layout';

import {
  approveIcon,
  designIcon,
  manageIcon,
  notificationIcon
} from 'assets/icons';

import {
  contractorSecondaryImage,
  timesheetApproval
} from 'assets/images';

/**
 * ForContractors
 *
 * @description Features page
 */
const ForContractors: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <NavigationMenu />
      <header>
        <SplitSection
          title={<><span>Are you a contractor</span> and tired of manual timesheets?</>}
          content='Say goodbye to excel spreadsheets and start letting us manage all your timesheets allowing you to easily export and get them approved.'
          image={timesheetApproval}
          contentLeftAligned
          action={{
            label: 'Get started',
            icon: <IoPaperPlaneOutline />,
            link: 'https://app.whttime.com.au/'
          }}
        />
      </header>
      <main>
        <FeatureSection
          id='featuresList'
          title='Is your recruiter making you submit timesheets in excel spreadsheets or worse?'
        >
          <FeatureList
            items={[
              {
                title: 'Manage timesheets',
                alt: 'Manage timesheets',
                img: manageIcon,
                content: 'Manage all your timesheets for a variety of contracts in the one place. With the ability to sort and filter timesheets saving you time.'
              },
              {
                title: 'Automated approvals',
                alt: 'Automated approvals',
                img: approveIcon,
                content: 'Approvals are now easier than ever. Approve either as a guest or a user with features such as bulk approvals and review contract details.'
              },
              {
                title: 'UI designed for humans',
                alt: 'UI designed for humans',
                img: designIcon,
                content: 'We have put a lot of time in designing an interface that is easy to understand, helping improve the experience of all of our users.'
              },
              {
                title: 'Let us do the reminding',
                alt: 'Let us do the reminding',
                img: notificationIcon,
                content: 'Don\'t spend your time sending out reminder emails, let us do it. This helps ensure business operations stay on track and avoid awkward emails.'
              }
            ]}
          />
        </FeatureSection>

        <SplitSection
          title={<>Want to know the best part? <span>It&apos;s free</span></>}
          content='Get started today for free and let us take the hard work out of managing timesheets. Simply sign-up and begin filling out timesheets and getting them approved.'
          image={contractorSecondaryImage}
          action={{
            label: 'Get started',
            icon: <IoPaperPlaneOutline />,
            link: 'https://app.whttime.com.au/'
          }}
        />
      </main>
      <Footer />
    </>
  );
};

export default ForContractors;