import React from 'react';
import { useHistory } from 'react-router-dom';

import { FeatureList } from 'components/common';
import {
  Footer,
  NavigationMenu,
  SplitSection,
  FeatureSection
} from 'components/layout';

import {
  approveIcon,
  manageIcon,
  reportingIcon,
  xeroIcon
} from 'assets/icons';

import {
  headerImage,
  pricingHeaderImage
} from 'assets/images';

const Home: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <NavigationMenu />

      <header>
        <SplitSection
          title={<><span>Take control</span> of your timesheets and manage them in one place</>}
          content='Say goodbye to spreadsheets and start letting us manage your timesheets, allowing you to effortlessly get them approved.'
          image={headerImage}
          contentLeftAligned
          action={{
            label: 'See our features',
            onClick: () => history.push('/features#featuresList')
          }}
        />
      </header>
      <main>
        <FeatureSection
          id='featuresList'
          title={<>We are <span>always evolving</span> and looking for the features which bring the most value to your <span>business operations</span></>}
        >
          <FeatureList
            items={[
              {
                title: 'Manage timesheets',
                alt: 'Manage timesheets',
                img: manageIcon,
                content: 'Manage all your timesheets for a variety of contracts in the one place. With the ability to sort and filter timesheets saving you time.'
              },
              {
                title: 'Automated approvals',
                alt: 'Automated approvals',
                img: approveIcon,
                content: 'Approvals are now easier than ever. Approve either as a guest or a user with features such as bulk approvals and review contract details.'
              },
              {
                title: 'Key business reporting',
                alt: 'Key business reporting',
                img: reportingIcon,
                content: 'We know that data drives your business and helps plan for the future. We offer a range of reports on key figures helping support your business.'
              },
              {
                title: 'Xero integration',
                alt: 'Xero integration',
                img: xeroIcon,
                content: 'We know you love to use Xero, so we have built native integration, allowing you to create your invoices in Xero directly from our system and keep track of their status.'
              }
            ]}
          />
        </FeatureSection>

        <SplitSection
          title={<>We believe in simple and <span>transparent pricing</span></>}
          content='We keep our pricing transparent and simple so you know what you&apos;ll be paying up front without surprises.'
          image={pricingHeaderImage}
          action={{
            label: 'See our pricing',
            onClick: () => history.push('/pricing#pricingList')
          }}
        />
      </main>
      <Footer />
    </>
  );
};

export default Home;