import React from 'react';
import axios from 'axios';
import TagManager from 'react-gtm-module';

import { Form, FormInput, Input, SelectInput, Loader } from 'components';

import styles from './ContactForm.module.scss';

interface ContactFormData {
  reason?: string;
  organisation?: string;
  email?: string;
  message?: string;
}

const ContactForm: React.FC = () => {
  const handleValidate = (formData: ContactFormData): boolean => !!formData.email && !!formData.message && !!formData.reason;

  // Submit contact form
  const handleSubmit = async (formData: ContactFormData) => {
    window.gtag('event', 'contact_submit', {
      contact_type: formData.reason
    });

    return await axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_BASE_URL}/contact.php`,
      headers: { 'content-type': 'application/json' },
      data: formData
    });
  };

  return (
    <Form<ContactFormData>
      id='contactForm'
      onValidate={handleValidate}
      onSubmitAsync={handleSubmit}
      className={styles.contact}>
      {({ formData, formSubmitted, formError, formLoading, setFieldValue }) =>
        (
          <>
            {!formSubmitted ?
              <>
                <FormInput
                  title='Why are you getting in touch?'
                  helpText='We would love to hear about all kinds of things.'>
                  <SelectInput
                    required
                    name='reason'
                    placeholder='Select a reason...'
                    handleChange={setFieldValue}
                    value={formData.reason ?? ''}
                    options={[
                      { value: 'feedback', label: 'Feedback' },
                      { value: 'demo', label: 'Schedule demo' },
                      { value: 'features', label: 'Feature suggestion' },
                      { value: 'billing', label: 'Billing question' },
                      { value: 'support', label: 'Support request' },
                      { value: 'other', label: 'Everything else' }
                    ]} />
                </FormInput>

                <FormInput
                  title='Who are you working with?'
                  helpText='Let us know who we are talking to.'>
                  <Input
                    name='organisation'
                    placeholder='Happy company'
                    value={formData.organisation ?? ''}
                    handleChange={setFieldValue} />
                </FormInput>

                <FormInput
                  title='How can we reach you?'
                  helpText='Let us know how to get back to you, we don&apos;t like leaving you on read.'>
                  <Input
                    name='email'
                    placeholder='happycustomer@company.com'
                    value={formData.email ?? ''}
                    handleChange={setFieldValue}
                    type='email'
                    required />
                </FormInput>

                <FormInput
                  title='Enter your comments or feedback'
                  helpText='Give us some feedback so you can help us improve our service.'>
                  <Input
                    name='message'
                    placeholder='Tell us how great (or not) we are...'
                    value={formData.message ?? ''}
                    handleChange={setFieldValue}
                    multiline
                    required />
                </FormInput>

                <p className={styles.error}>{formError}</p>

                <div className='pushRight'>
                  <Loader isLoading={formLoading}>
                    <button
                      type='submit'
                      className='primary'>Submit</button>
                  </Loader>
                </div>
              </>
              :
              <p className='center'>Thanks for your message! We&apos;ll be in touch shortly.</p>
            }
          </>
        )}
    </Form>
  );
};

export default ContactForm;