import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import { FeatureList } from 'components/common';
import {
  Footer,
  FeatureSection,
  SplitSection,
  NavigationMenu
} from 'components/layout';

import {
  analyticsDashboardIcon,
  approveIcon,
  designIcon,
  manageIcon,
  notificationIcon,
  reportingIcon,
  siginingIcon,
  xeroIcon
} from 'assets/icons';

import {
  featuresHeaderImage,
  featuresSecondaryImage
} from 'assets/images';

/**
 * Features
 *
 * @description Features page
 */
const Features: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <NavigationMenu />

      <header>
        <SplitSection
          title={<><span>Designed</span> around the features your business needs</>}
          content='We offer a range of features to support your business functions: contractor onboarding, timesheet approvals and reporting. Our feature set is always growing as we try deliver the features you need.'
          image={featuresHeaderImage}
          contentLeftAligned
          action={{
            label: 'See all our features',
            link: '#featuresList'
          }}
        />
      </header>
      <main>
        <FeatureSection
          id='featuresList'
          title={<>We are <span>always evolving</span> and looking for the features which bring the most value to your <span>business operations</span></>}
        >
          <FeatureList
            items={[
              {
                title: 'Manage timesheets',
                alt: 'Manage timesheets',
                img: manageIcon,
                content: 'Manage all your timesheets for a variety of contracts in the one place. With the ability to sort and filter timesheets saving you time.'
              },
              {
                title: 'Automated approvals',
                alt: 'Automated approvals',
                img: approveIcon,
                content: 'Approvals are now easier than ever. Approve either as a guest or a user with features such as bulk approvals and review contract details.'
              },
              {
                title: 'Key business reporting',
                alt: 'Key business reporting',
                img: reportingIcon,
                content: 'We know that data drives your business and helps plan for the future. We offer a range of reports on key figures helping support your business.'
              },
              {
                title: 'Xero integration',
                alt: 'Xero integration',
                img: xeroIcon,
                content: 'We know you love to use Xero, so we have built native integration, allowing you to create your invoices in Xero directly from our system and keep track of their status.'
              },
              {
                title: 'UI designed for humans',
                alt: 'UI designed for humans',
                img: designIcon,
                content: 'We have put a lot of time in designing an interface that is easy to understand, helping improve the experience of all of our users.'
              },
              {
                title: 'Let us do the reminding',
                alt: 'Let us do the reminding',
                img: notificationIcon,
                content: 'Don\'t spend your time sending out reminder emails, let us do it. This helps ensure business operations stay on track and avoid awkward emails.'
              },
              {
                title: 'Digital signing',
                alt: 'Digital signing',
                img: siginingIcon,
                tag: 'Coming soon',
                content: 'We believe in a digital first world and with that we think that signing contracts should be part of the contractors digital experience.'
              },
              {
                title: 'Analytics dashboards',
                alt: 'Analytics dashboards',
                img: analyticsDashboardIcon,
                tag: 'Coming soon',
                content: 'Although reports are great, live analytics dashboards give you a real-time view of your business and are an important part of forecasting business operations.'
              }
            ]}
          />
        </FeatureSection>

        <SplitSection
          title={<>Got an idea for a<br /> new feature? <br /><span>We want to hear it</span></>}
          content='We are always working on new features and want to prioritise the ones you need most. If there&apos;s something you need that we don&apos;t offer yet, please reach out!'
          image={featuresSecondaryImage}
          action={{
            label: 'Tell us about it',
            icon: <IoPaperPlaneOutline />,
            onClick: () => history.push('/contact')
          }}
        />
      </main>
      <Footer />
    </>
  );
};

export default Features;