import React from 'react';

import './FeatureSection.scss';

/**
 * IFeatureSection
 *
 * @description Component props for Feature section
 */
interface IFeatureSection {
  id?: string;
  title: string | React.ReactNode;
  description?: string;
}

/**
 * FeatureSection
 *
 * @description Feature section
 */
const FeatureSection: React.FC<IFeatureSection> = ({ id, title, description, children }) => (
  <section id={id} className='feature-section'>
    <div className='feature-section__inner'>
      <h2 className='feature-section__title'>{title}</h2>
      {
        description &&
          <p className='feature-section__description'>{description}</p>
      }
    </div>
    {children}
  </section>
);

export default FeatureSection;
