import React from 'react';

import './Button.scss';

/**
 * IButton
 *
 * @description Button props
 */
interface IButton {
  type?: 'primary' | 'secondary' | 'ghost';
  className?: string;
  label?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  link?: string;
  onClick?: () => void;
}

/**
 * Button
 *
 * @description Button component
 */
const Button: React.FC<IButton> = ({ className, label, disabled, icon, link, onClick, type = 'primary' }) => {
  if (link) {
    return (
      <a
        className={`button button--link button--${type} ${className ? className : ''}`}
        href={link}
      >
        {label}
        {
          icon &&
            icon
        }
      </a>
    );
  }

  return (
    <button
      className={
        `button button--${type} ${className ? className : ''} ${disabled ? 'button--disabled' : ''}`
      }
      disabled={disabled ?? false}
      onClick={onClick}>
      {label}
      {
        icon &&
          icon
      }
    </button>
  );
};

export default Button;
